<template>
  <div id="vueModuleMenu">
      <MenuHeader/>
  </div>
</template>

<script>
import MenuHeader from "./components/Menu.vue";

export default {
  name: "vueModuleMenu",
  components: {
    MenuHeader
  }
};
</script>

